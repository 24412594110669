import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/common/services/common.service';
import { UserService } from 'src/app/common/services/user.service';
// import { UserService } from 'src/app/common/services/user.service';
// import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, AfterViewInit {
  public route: string = '';
  constructor(
    // private _user: UserService,
    // private analytics: AngularFireAnalytics
    public _user: UserService,
    private _router: Router,
    public _common: CommonService
  ) {
    //TODO valdiar cuando es la mejor forma de inicializar el
    this._user.getLocation();
    this.route = _router.url;
    _router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.route = event.url;
    })
  }
  ngOnInit() {
    this._user.getUserFirebase(this._user.user).subscribe({
      next: (a: any) => {
        // console.log('getUser - rooot', a);
        if (a.type = 'added') {
          //       if (this._user.user_fire_ref && this._user.firstCharge) {
          // this._user.user_fire_ref.update({ last_connection: Date.now(), navigator: navigator.userAgent })
          //         this._user.firstCharge = false;
          //         this._user.user_firebase = a.data;
          //       }
        } else if (a.type = 'modified') {
          // this._user.user_firebase = a.data;
        } else if (a.type = 'removed') {
          this._user.logOut();
        }
      }
    });
  }
  ngAfterViewInit(): void {
  }


}
