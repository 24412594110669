import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { MD5 } from 'crypto-js';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { environment } from 'src/environments/environment';

@Injectable()
export class StorageInterceptor implements HttpInterceptor {
  // private db: any;
  constructor(
    private dbService: NgxIndexedDBService
  ) {

    // const DBOpenRequest = window.indexedDB.open("platform", 1);
    // DBOpenRequest.onsuccess = (event) => {
    //   this.db = DBOpenRequest.result;
    //   console.info('DBOpenRequest', event, this.db);
    // };
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let storage = request.headers.get('AgrodatAi-Storage');
    // console.log('AgrodatAi-Storage', request.headers, request.headers.get('AgrodatAi-Storage'));
    if(storage){
      let response = this.ResponseStorage(request);
      if(response){
        // console.log('-----> ResponseStorage', response);
        return of(new HttpResponse({ "body": response }));
      }
    }

    const request_clone = request.clone({
      headers: request.headers.delete('AgrodatAi-Storage')
    });

    return next.handle(request_clone).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          // console.log('-----> SaveResponseStorage');
          this.SaveResponseStorage(request, event.clone(), storage);
        }
      })
    )
  }

  getKey(request: HttpRequest<unknown>){
    let request_body = JSON.stringify(request.body); 
    return [MD5(request_body + request.url + request.params.toString()).toString(), request_body];
  }

  //TODO almacenar en sesionstorage o localstorage, cuando no venga la marca de la peticion de indexeddb
  SaveResponseStorage(request: HttpRequest<unknown>, record:any, storage: any){
    let [key, request_body] = this.getKey(request);
    let response_body = {
      id: key,
      url: request.url,
      create_date: Date.now(),
      expires_in: new Date(Date.now() + (1000 * environment.expires_cache)).getTime(),
      request: request_body,
      queryparams: request.params.toString(),
      body: JSON.stringify(record.body)
    };
    // TODO agregar la validacion de opciones de almacenamiento en el navegador
    // console.log('SaveResponseStorage', key, request_body, request.url, record, request.headers.get('AgrodatAi-Storage'));
    if(storage){
      // TODO encriptar la inforamcion con un metodo que nos permita acceder a ella
      response_body.expires_in = (storage != 'true')? new Date(Date.now() + (1000 * parseInt(storage))).getTime() : response_body.expires_in;
      this.dbService.count('request', key).subscribe((peopleCount) => {
        // console.log('key count', key, peopleCount, Date.now());
        if(peopleCount < 1){
          this.dbService.add('request', response_body).subscribe((key) => {
            // console.log('SaveResponseStorage - key: ', key);
          });
        }
      });

    }else{
      if(environment.default_storage){
        localStorage.setItem(key, JSON.stringify(response_body));
      }
    }
  }

  ResponseStorage(request: HttpRequest<unknown>){
    let [key] = this.getKey(request);
    // let key = MD5(JSON.stringify(request.body)+request.url+request.params.toString()).toString();
    // console.log('ResponseStorage', key, request.params.toString(), request.body, request.url, request.headers.get('AgrodatAi-Storage'));
    let data:any = localStorage.getItem(key);
    if(data){
      data = JSON.parse(data);
      if(data.expires_in > Date.now()){
        return JSON.parse(data.body);
      }else{
        localStorage.removeItem(key);
      }
    }
    return null
  }

}
