import { AbstractComponentTranslate } from '@agrodatai/core';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/services/common.service';
import { StartAppService } from 'src/app/common/services/startApp.service';
import { UserService } from 'src/app/common/services/user.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AiInformationService, AiNotificationService } from '@agrodatai/information';
interface AlertData {
  alert: any;
  content: string;
  end_date: any;
  start_date: any;
  view: boolean
}
@Component({
  selector: 'ai-home-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AbstractComponentTranslate implements OnInit, AfterViewInit {
  public isLoading: boolean = true;
  logoStyle: any = {};
  lateralOpen: boolean = false;
  public ModalOpen: boolean = false;
  public menuStyle = {
    width: '4rem',
    height: '4rem',
    'border-radius': '5px'
  }

  constructor(
    @Inject('UserService') public _userOne: any,
    private _firestore: AngularFirestore,
    public _common: CommonService,
    public _user: UserService,
    private _start: StartAppService,
    private _information: AiInformationService,
    private _notification: AiNotificationService
  ) {
    super(_start, 'menu');
    this.logoStyle = this.menuStyle;
    this.logoStyle['width'] = '6rem';
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoading = false;
      setTimeout(() => {
        this._notification.printNotificationCount('ai-bell-notifications');
      }, 100);
    }, 500);
  }

  updateModal() {
    this._information.viewState = 'open';
  }
  openNotifications() {
    this._information.interactMenu('open', 'notification');
  }
  openMenu(){
    this._information.interactMenu('noBackdropOpen', 'lateral');
  }

  shareApp() {
    navigator.share({
      title: 'Descarga la aplicación AgrodatAI',
      text: 'Obtén información para tomar mejores decisiones en el agro.',
      url: 'https://agrodatai.page.link/CQm1',
    }).then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
}
