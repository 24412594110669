import { Injectable } from '@angular/core';
import { Observable, combineLatest, delay, interval, of, switchMap, take, takeUntil, zip } from 'rxjs';
import { CredentialsService } from './credentials.service';
import { UserService } from './user.service';
import { AbstractServiceTranslate } from '@agrodatai/core';

@Injectable({
  providedIn: 'root'
})
export class StartAppService extends AbstractServiceTranslate {
  public FirstLoad: boolean = true;
  public routeToLoad: string | string[] | undefined;
  public routeToRedirect: string | undefined;
  constructor(
    private _credentials: CredentialsService,
    private _user: UserService
  ) {
    super();
  }

  configureApp(route: string): Observable<any> {
    const subscriptions: any[] = [];
    const routeArr = route.split('/');
    if (!this._credentials.platformAccessToken) {
      this._credentials.timerToken = true;
      subscriptions.push({ id: 'platformAccessToken', sub: this._credentials.requestPlatformToken() });
    }

    let search_token = false;
    const current = this._user.tokenis_current();
    let is_user: any = false;

    if (current == false && this._user.user) {
      if (!this._credentials.timerToken) {
        subscriptions.push({ id: 'getToken', sub: this._user.getToken() });
        search_token = true;
        is_user = true;
      }
    } else if (current && this._user.user) {
      is_user = true;
    } else {
      is_user = null;
    }

    let get_token = subscriptions.filter(e => e.id == 'getToken');
    let route_2 = route;
    if (current || search_token) {
      if (!this._user.user_firebase) {
        subscriptions.push({ id: 'getUserFirebase', sub: this._user.getUserFirebase(this._user.user) });
      }
    } else {
      if (!this._credentials.timerToken) {
        this._user.clear();
      }
    }
    return new Observable((observer) => {
      if (subscriptions.length > 0) {
        zip(subscriptions.flatMap(element => element.sub)).pipe(take(1)).subscribe({
          next: (resArr: any[]) => {
            if (this._credentials.timerToken) {
              this._credentials.timerToken = false;
            }
            if ((is_user || get_token.length > 0) && route.search("home") < 0) {
              route_2 = '/home';
            } else if (route.search("home") > 0) {
              if (is_user == false && get_token.length == 0) {
                route_2 = '/';
              }
            }
            observer.next({ status: route == route_2, route: route_2 });
          },
          error: (err: any) => {
            console.log(err);
            observer.next({ status: route == route_2, route: route_2 });
          }
        });
      } else {
        if (is_user && route.search("home") < 0) {
          route_2 = '/home';
        } else if (is_user == null && route.search("home") > 0) {
          if (!this.routeToRedirect) this.routeToRedirect = route;
          route_2 = '/';
        } else if (is_user && route.search("home") > 0) {
          if (this.routeToRedirect) {
            route_2 = `${this.routeToRedirect}`;
            this.routeToRedirect = undefined;
          }
        }
        observer.next({ status: route == route_2, route: route_2 });
      }
    });
  }

  configureAppFn(route: string) {
    let firstSub: Observable<any> | undefined = undefined;
    let secondSub: Observable<any> | undefined = undefined;
    let thirdSub: Observable<any> | undefined = undefined;
    if (!this._credentials.platformToken && !this._credentials.platformAccessToken) {
      firstSub = this._credentials.requestPlatformToken();
    }
    const current = this._user.tokenis_current();
    if (current == false && this._user.user) {
      if (!this._user.myTimeout) {
        if (!firstSub) firstSub = this._user.getToken();
        else if (!secondSub) secondSub = this._user.getToken();
      }
    }
    if (current && this._user.user && !this._user.user_firebase) {
      if (!firstSub) firstSub = this._user.getUserFirebase(this._user.user);
      else if (!secondSub) secondSub = this._user.getUserFirebase(this._user.user);
      else if (!thirdSub) thirdSub = this._user.getUserFirebase(this._user.user);
    }
    let mutated_path = route;
    return new Observable<{ status: boolean, route: string }>((observable) => {
      if (firstSub) {
        firstSub!.pipe(
          take(1),
          switchMap((response => {
            if (secondSub) return secondSub.pipe(
              switchMap(res => {
                if (thirdSub) return thirdSub;
                return of(res);
              })
            );
            return of(response);
          }))).subscribe({
            next: (res: any) => {
              if (this._user.user && route.search('home') < 0)
                mutated_path = '/home';
              else if (route.search("home") > 0 && !this._user.user && !this._user.token) {
                mutated_path = '/';
              }
              observable.next({ status: route == mutated_path, route: mutated_path });
            }
          })
      }
      else {
        if (this._user.user && route.search('home') < 0)
          mutated_path = '/home';
        else if (route.search("home") > 0 && !this._user.user && !this._user.token) {
          if (!this.routeToRedirect) this.routeToRedirect = route;
          mutated_path = '/';
        } else if (this._user.user && route.search('home') > 0) {
          if (this.routeToRedirect) {
            mutated_path = `${this.routeToRedirect}`;
            this.routeToRedirect = undefined;
          }
        }
        observable.next({ status: route == mutated_path, route: mutated_path });
      }
    })
  }
}
