import { AbstractComponentTranslate } from '@agrodatai/core';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/common/services/common.service';
import { StartAppService } from 'src/app/common/services/startApp.service';

export interface OptionMenu {
  path: string,
  image: string,
  select: boolean
  redirect?: boolean,
  toRedirect?: string | string[],
  externalLink?: string,
  external: boolean,
  size: string[],
  id: string,
  text?: string,
  disabled?: boolean,
  order?: {
    web: number,
    mobile: number
  }
}

@Component({
  selector: 'ai-home-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends AbstractComponentTranslate implements OnInit {

  //FIXME: El menú debe cargarse dinamicamente según permisos
  public override form_elements: any = {
    'option': [
      {
        controlname: 'farms',
        external: false,
        image: 'farm.png',
        path: 'https://storage.googleapis.com/front-agrodatai-dev/assets/',
        select: false,
        redirect: true,
        toRedirect: ['/home/farms'],
        size: ['3.5rem', '2.5rem'],
        id: 'farms'
      },
      {
        controlname: 'prices',
        external: false,
        image: 'coin.png',
        path: 'https://storage.googleapis.com/front-agrodatai-dev/assets/',
        select: false,
        redirect: true,
        toRedirect: ['/home/prices'],
        size: ['2.5rem', '2.5rem'],
        id: 'prices',
        disabled: false
      },
      {
        controlname: 'better',
        external: false,
        image: 'plaza.png',
        path: 'https://storage.googleapis.com/front-agrodatai-dev/assets/',
        select: false,
        redirect: true,
        toRedirect: ['/home/better'],
        size: ['3rem', '2.5rem'],
        id: 'plaza',
        disabled: false
      },
      {
        controlname: 'weather',
        external: false,
        image: 'weather.png',
        path: 'https://storage.googleapis.com/front-agrodatai-dev/assets/',
        select: false,
        redirect: true,
        toRedirect: ['/home/weather'],
        size: ['3.5rem', '2.5rem'],
        id: 'weather'
      },
      // {
      //   controlname: 'supplies',
      //   external: false,
      //   image: 'seeds.png',
      //   path: 'https://storage.googleapis.com/front-agrodatai-dev/assets/',
      //   select: false,
      //   redirect: false,
      //   size: ['2.3rem', '2.5rem'],
      //   id: 'supply'
      // },
    ]
  }

  constructor(
    private _start: StartAppService,
    private _router: Router,
    public _common: CommonService
  ) {
    super(_start, 'header-menu');
  }

  ngOnInit() {
  }

}
