import { Injectable } from '@angular/core';
import { UtilsService } from '@agrodatai/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { UserService } from '../user.service';
import { CredentialsService } from '../credentials.service';
import { environment } from 'src/environments/environment';
import { AiAlert } from '@agrodatai/alerts';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private _user: UserService,
    private _credentials: CredentialsService,
    public _alerts: AiAlert,
    private _router: Router,
  ) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let endpoint: any = request.headers.get('AgrodatAi-Token');

    let token: any;
    if (endpoint == 'user')
      token = this._user.token?.access_token;
    else if (endpoint == 'page')
      token = this._credentials.platformAccessToken;

    if (request.url != `${environment.BACKEND_URL}auth/token/`) {
      let headers = request.headers.delete('AgrodatAi-Token');
      const modifiedRequest = request.clone({
        headers: headers.set('Authorization', `Bearer ${token}`),
      });
      return next.handle(modifiedRequest).pipe(
        catchError((error) => {
          // if (request.url.includes('auth/refresh_token/')) {
          //   this._user.clear();
          //   this._router.navigate(['/login']);
          // };
          if (error.status === 401) {
            // TODO: validar las traducciones en los mensajes
            this._alerts.readyAlert({
              type: 'error',
              image: 'explode_head',
              title: 'Error',
              text: 'Token inválido o expirado',
              timer: 3000, showConfirmButton: false
            })
            console.error(error);
            this._user.clear();
            this._router.navigate(['/login']);
            return this.handleUnauthorizedError();
          } else {
            [environment.BACKEND_BETTER_URL, environment.BACKEND_PRICES_URL, environment.BACKEND_WEATHER_URL, 'auth/refresh_token/'].map((item: any) => {
              if (request.url.includes(item)) this._credentials.need_alert = false;
            });
            // console.warn(request.url, 'auth/refresh_token/', request.url.includes('auth/refresh_token/'));
            if (request.url.includes('auth/refresh_token/')) {
              this._user.clear();
              this._router.navigate(['/login']);
              this._alerts.readyAlert({
                type: 'error',
                image: 'explode_head',
                title: 'Error',
                text: 'Token inválido o expirado',
                timer: 3000, showConfirmButton: false
              })
              return this.handleUnauthorizedError();
            } else if (this._credentials.need_alert) {
              this._alerts.readyAlert({
                type: 'error',
                image: 'explode_head',
                title: 'Error',
                text: 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde',
                timer: 3000, showConfirmButton: false
              })
            }
            return throwError(error);
          }

        })
      )
    } else {
      return next.handle(request);
    }
  }

  private handleUnauthorizedError(): Observable<any> {
    return throwError('Token inválido o expirado');
  }

}
