import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, combineLatest, map, zip } from 'rxjs';
import { CommonService } from 'src/app/common/services/common.service';
import { CredentialsService } from 'src/app/common/services/credentials.service';
import { StartAppService } from 'src/app/common/services/startApp.service';
import { UserService } from 'src/app/common/services/user.service';

@Component({
  selector: 'ai-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class LoaderComponent implements OnInit {

  constructor(
    public _common: CommonService,
    private _start: StartAppService,
    private _router: Router,
    private _user: UserService,
    private _credentials: CredentialsService
  ) { }

  ngOnInit() {
    this.configureApp();
  }

  private configureApp = async () => {
    // if (this._start.FirstLoad) {
    //   const subscriptions: Observable<any>[] = [];
    //   //? Cuanto dura el platform Token
    //   if (!localStorage.getItem('ai-platform-token')) {
    //     subscriptions.push(this._credentials.requestPlatformToken());
    //   }
    //   else {
    //     // this._credentials.platformToken;
    //     this.autoGenerateTokenPlatform();
    //   };

    //   //* Si el usuario se encuentra logueado
    //   if (this._user.user) {
    //     subscriptions.push(this._user.getUserFirebase2(this._user.user));
    //   }

    //   if (subscriptions.length > 0)
    //     combineLatest(subscriptions).subscribe({
    //       next: (dataArr) => {
    //         const keyStructures = [
    //           'access_token',
    //           'is_active'
    //         ]
    //         if (dataArr.length > 0) {
    //           dataArr.map((value: any) => {
    //             let typeStructure: number = -1;
    //             keyStructures.map((key: string, index: number) => {
    //               const keysMatch = Object.keys(value).includes(key);
    //               if (keysMatch) typeStructure = index;
    //             })
    //             console.log(typeStructure);

    //             switch (typeStructure) {
    //               case 0:
    //                 // this._credentials.platformToken = { ...value, generated: Date.now() };
    //                 this.autoGenerateTokenPlatform();
    //                 break;
    //               case 1:
    //                 this._user.user_firebase = value;
    //                 if (this._user.firstCharge) {
    //                   if (this._user.user_fire_ref) {
    //                     this._user.user_fire_ref.update({ last_connection: Date.now(), navigator: navigator.userAgent })
    //                     this._user.firstCharge = false;
    //                   }
    //                 }
    //                 break;
    //             }
    //           })
    //           this.returnToRoute()
    //         }
    //       },
    //     })
    //   else this.returnToRoute()
    // } else this.returnToRoute()
  }

  private returnToRoute() {
    setTimeout(() => {
      this._start.FirstLoad = false;
      if (this._start.routeToLoad)
        this._router.navigate([this._start.routeToLoad])
      else this._router.navigate(['/'])
    }, 1000)
  }

  private autoGenerateTokenPlatform() {
  //   const retryIn = parseInt(`${this._credentials.platformToken?.expires_in}000`);
  //   if (this._credentials.platformToken && (this._credentials.platformToken?.generated + retryIn) > Date.now()) {
  //     const missingTime = (this._credentials.platformToken?.generated + retryIn) - Date.now();
  //     setTimeout(() => {
  //       this._credentials.requestPlatformToken().subscribe({
  //         next: (res: any) => {
  //           // this._credentials.platformToken = { ...res, generated: Date.now() };
  //           // this.autoGenerateTokenPlatform();
  //         }
  //       })
  //     }, missingTime);
  //   } else {
  //     this._credentials.requestPlatformToken().subscribe({
  //       next: (res: any) => {
  //         // this._credentials.platformToken = { ...res, generated: Date.now() };
  //         // this.autoGenerateTokenPlatform();
  //       }
  //     })
  //   }
  }
}
