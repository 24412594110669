import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { InitCanActivateFn } from 'src/app/common/services/guards/init.guard';
import { FarmsCanActivateChildFn, FarmsCanActivateFn } from 'src/app/common/services/guards/farms.guard';
import { NotificationsResolveFn, ProfileComponent } from '@agrodatai/information';


const routes: Routes = [
  {
    path: 'credit',
    canActivate: [InitCanActivateFn, FarmsCanActivateFn],
    loadChildren: () => import("@agrodatai/credit").then(m => m.AiCreditModule),
    title: 'AgrodatAi - Credit'
  },
  {
    path: 'farms',
    canActivate: [InitCanActivateFn],
    canActivateChild: [FarmsCanActivateChildFn],
    loadChildren: () => import("@agrodatai/farms").then(m => m.AiFarmsModule),
    title: 'AgrodatAi - Farms'
  },

  // {
  //   path: 'connect',
  //   canActivateChild: [TokenGuard],
  //   loadChildren: () => import("@agrodatai/connect").then(m => m.AiConnectModule)
  // },
  {
    path: 'profile',
    component: ProfileComponent,
    loadChildren: () => import('@agrodatai/information').then(m => m.ProfileModule)
  },
  {
    path: 'insurance',
    loadChildren: () => import('@agrodatai/insurance').then((m) => m.AiInsuranceModule)
  },
  {
    path: '',
    canActivate: [InitCanActivateFn, FarmsCanActivateFn],
    component: HomeComponent,
    title: 'AgrodatAi - Home',
  },
];

export const RootRoutes = RouterModule.forChild(routes);
