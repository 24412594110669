import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RootComponent } from './modules/root/root.component';
import {UnsubscribeComponent} from './modules/unsubscribe/unsubscribe.component'
import { TokenCanActivateChildFn } from './common/services/guards/token.guard';
import { InitCanActivateFn } from './common/services/guards/init.guard';
import { LoaderComponent } from './components/loader/loader.component';
import { NotificationsResolveFn } from '@agrodatai/information';
import { AiPricesHomeResolveFn } from '@agrodatai/prices';
import { WeatherHomeResolveFn } from "@agrodatai/weather";
import { BetterHomeResolveFn } from "@agrodatai/better";
import { PublicityResolveFn } from '@agrodatai/publicity';

const routes: Routes = [
  {
    path: 'home',
    component: RootComponent,
    canActivate: [InitCanActivateFn],
    canActivateChild: [
      TokenCanActivateChildFn,
    ],
    loadChildren: () => import("./modules/root/root.module").then(m => m.RootModule),
    title: 'AgrodatAi - Home',
    resolve: {
      notifications: NotificationsResolveFn,
      prices: AiPricesHomeResolveFn,
      weather: WeatherHomeResolveFn,
      better: BetterHomeResolveFn,
      publicities: PublicityResolveFn
    }
  },

  {
    path: 'unsubscribe',
    component: UnsubscribeComponent,
    canActivate: [InitCanActivateFn],
    loadChildren: () => import("./modules/unsubscribe/unsubscribe.module").then(m => m.UnsubscribeModule),
    title: 'AgrodatAi - Unsubscribe'
  },
  {
    path: 'loader',
    component: LoaderComponent,
  },
  {
    path: '',
    canActivate: [InitCanActivateFn],
    loadChildren: () => import("@agrodatai/entry").then(m => m.AiEntryModule)
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
