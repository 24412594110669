<header class="ai-header">
  <img [routerLink]="['/home']" *aiskeleton="isLoading; theme: logoStyle;" class="ai-header-logo"
    src="https://storage.googleapis.com/agrodata-ai-dev.appspot.com/assets/svg/--logo-main.svg" alt="">
  <div *ngIf="_common.device === 'web'" class="ai-menu-content-loading ai-menu">
    <div class="" *aiskeleton="isLoading; theme: menuStyle; repeat: 5"></div>
    <ai-home-menu class="" *ngIf="!isLoading"></ai-home-menu>
  </div>
  <div *aiskeleton="isLoading; theme: menuStyle;" class="ai-header-lateral">
    <i id="ai-bell-notifications" class="ai-icon-bell color-purple-1" (click)="openNotifications()"></i>
    <img (click)="openMenu()" class="ai-user-icon" [src]="_user.user?.person?.image" alt="">
  </div>
</header>
