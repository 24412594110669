<div class="ai-home-container family-quicksand">
  <ai-publicity-home *aiskeleton="isLoading; theme: bannerStyle;" class="w-100"></ai-publicity-home>
  <div class="ai-home-sub-container">
    <ai-prices-preview class="w-100"></ai-prices-preview>
    <ai-weather-home class="w-100"></ai-weather-home>
    <ai-better-home class="w-100"></ai-better-home>
    <div class="ai-spam-credit">
      <h1 class="color-green-1">Gestión de apoyo para solicitudes</h1>
      <div class="cards">
        <div (click)="redirect(card)" class="card {{card.classList}}" *ngFor="let card of spamCards">
          <span>{{translation && translation[card.title]? translation[card.title]: card.title + '?'}}</span>
          <img class="card-image" [src]="card.image" alt="">
        </div>
      </div>
    </div>
    <ai-news-home class="w-100"></ai-news-home>
    <ai-indicators-home class="w-100"></ai-indicators-home>
  </div>
</div>