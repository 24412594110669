<!-- <ai-loader></ai-loader> -->
<!-- <div *ngIf="banner && prod" class="banner">
  <div style="margin: 0px;">
    <div>
      <label style="margin: 0px;">
        <h3>Tu App AgrodatAI</h3><br>
        obtener nuestra aplicacion gratuita.
      </label>
    </div>
    <div style="text-align: center;">
      <div class="ai-btnicon-l" (click)="install()">Instalar</div>
    </div>
    <div style="text-align: center;" (click)="banner=!banner">
      <span>X</span>
    </div>
  </div>
</div>
<div *ngIf="bannerIOS && prod" class="ios">
  <div style="margin: 0px;">
    <div>
      <label style="margin: 0px;">
        <h3>Tu App AgrodatAI</h3><br>
        obtener nuestra aplicacion gratuita. <br>
        Oprime <img src="../assets/img/iphonesafari.jpg" style="width: 20px;"> para continuar la instalación.
      </label>
    </div>
    <div style="text-align: center;" (click)="bannerIOS=!bannerIOS">
      <span>X</span>
    </div>
  </div>
</div> -->

<router-outlet></router-outlet>

<lib-ai-alert></lib-ai-alert>