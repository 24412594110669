import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootComponent } from './root.component';
import { HomeComponent } from '../home/home.component';
import { MenuComponent } from 'src/app/components/menu/menu.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { ModalComponent } from '../../components/modal/modal.component'
import { RootRoutes } from './root.routing';
import { AbstractModuleTranslate, AiSkeletonDirective } from '@agrodatai/core';
import { SubMenuComponent } from 'src/app/components/sub-menu/sub-menu.component';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/common/services/common.service';
import { UserService } from 'src/app/common/services/user.service';
import { CredentialsService } from 'src/app/common/services/credentials.service';
import { AiPricesModule, AiPricesPreviewComponent } from '@agrodatai/prices';
import { BannersHomeComponent } from '@agrodatai/publicity';
import { AiBetterModule, BetterHomeComponent } from '@agrodatai/better';
import { AiNewsModule, NewsComponent } from '@agrodatai/news';
import { AiIndicatorsModule } from '@agrodatai/indicators';
import { AiWeatherHomeComponent, AiWeatherModule } from '@agrodatai/weather';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StartAppService } from 'src/app/common/services/startApp.service';
import { MapService } from '@agrodatai/farms';
import { AiInformationModule } from '@agrodatai/information';
@NgModule({
  declarations: [
    RootComponent,
    HomeComponent,
    MenuComponent,
    HeaderComponent,
    SubMenuComponent,
  ],
  imports: [
    CommonModule,
    RootRoutes,
    AiSkeletonDirective,
    AiPricesModule,
    BannersHomeComponent,
    AiBetterModule,
    AiWeatherModule,
    AiNewsModule,
    AiIndicatorsModule,
    AiInformationModule
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    { provide: 'commonService', useExisting: CommonService },
    { provide: 'UserService', useExisting: UserService },
    { provide: 'CredentialsService', useExisting: CredentialsService },
    MapService
  ]
})
export class RootModule extends AbstractModuleTranslate {
  constructor(
    private _dbFire: AngularFirestore,
    private _common: CommonService,
    private _start: StartAppService
  ) {
    super(_dbFire, _start, _common, 'ai-platform');
  }
}
