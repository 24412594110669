import { inject, } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';

export const TokenCanActivateChildFn: CanActivateChildFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, _router: Router = inject(Router)) => {
    //!FIXME: Esta validación debe eliminarse al integrar cada módulo
    //? Si solo se válida esta redirección es mejor eliminar este Guard
    return true
  }



