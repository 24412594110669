import { Component, OnInit } from '@angular/core';
import { OptionMenu } from '../menu/menu.component';
import { CommonService } from 'src/app/common/services/common.service';
import { AbstractComponentTranslate } from '@agrodatai/core';
import { StartAppService } from 'src/app/common/services/startApp.service';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { UserService } from 'src/app/common/services/user.service';

@Component({
  selector: 'ai-home-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent extends AbstractComponentTranslate implements OnInit {
  public openState: boolean = false;
  public options: OptionMenu[] = [
    {
      external: true,
      image: 'credit.png',
      path: 'https://storage.googleapis.com/front-agrodatai-dev/assets/',
      select: false,
      redirect: true,
      toRedirect: ['/home/credit'],
      size: ['3rem', '2rem'],
      id: 'credit',
      order: {
        web: 2,
        mobile: 1
      },
      externalLink: 'https://agrodatai.page.link/scDe'
    },
    {
      external: false,
      image: 'agro.png',
      path: 'https://storage.googleapis.com/front-agrodatai-dev/assets/',
      select: false,
      redirect: true,
      toRedirect: ['/home'],
      size: ['1.5rem', '2.5rem'],
      id: 'home',
      order: {
        web: 3,
        mobile: 2
      }
    },
    {
      external: false,
      image: 'security.svg',
      path: 'https://storage.googleapis.com/front-agrodatai-dev/agrodatai/home/menus/',
      select: false,
      redirect: true,
      size: ['2.5rem', '2.5rem'],
      id: 'insurance',
      disabled: false,
      order: {
        web: 1,
        mobile: 3
      },
      toRedirect: ['/home/insurance'],
      externalLink: 'https://agrodatai.page.link/aMj4'
    },
  ]

  constructor(
    public _common: CommonService,
    private _start: StartAppService,
    private _router: Router,
    private _user: UserService
  ) {
    super(_start, 'shorcuts');
  }

  ngOnInit() {
    setTimeout(() => { this.openState = true }, 200);
    setTimeout(() => { this.openState = false }, 3000);
  }
  validateHeader() {
    // TODO: Pasar esta validación al guard ?
    if (this._user.locations && this._user.locations.length > 0) {
      if (
        this._user.locations[0].products &&
        this._user.locations[0].products.length > 0
      ) return true;
      return false;
    }
    return false;
  }

  redirect(option: OptionMenu) {
    if (option.external) {
      window.open(option.externalLink, '_blank');
    }
    else this._router.navigate(option.toRedirect as Array<string>)
  }
}
