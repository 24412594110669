<div class="ai-agr-container">
  <div class="front-page">
    <img class="img"
      src="https://storage.googleapis.com/front-agrodatai-dev/agrodatai/img/material-templates/ImageUnsubscribe.png">
  </div>
  <div class="content">
    <div class="preferences">
      <p class="text-green">Preferencias de correo electrónico</p>
      <p class="text-purple">Personalice sus preferencias para laura.ramirez@agrodatai.com, para recibir el contenido
        que le interese y que lo inspire.</p>
      <p class="text-purple">Opción 1</p>
      <p class="text-purple">Opción 2</p>
      <p class="text-purple">Opción 3</p>
      <button class="btn-purple">Guardar cambios</button>
    </div>
    <hr class="line">
    <div class="cancellation">
      <p class="text-green">Cancelar todas las suscripciones</p>
      <p class="text-purple">Nos entristece que se vaya, pero aquí estaremos cuando quiera regresar. Como observación,
        una vez que cancele sus suscripciones, solamente le enviaremos los correos electrónicos relativos a sus créditos
      </p>
      <button class="btn-grey">Cancelar subscripción</button>
    </div>
  </div>
</div>