import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of, tap, catchError, throwError, Subscriber, timeout } from 'rxjs';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { MD5 } from 'crypto-js';
import Token from '../interfaces/Token';
import { UtilsService } from '@agrodatai/core';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  public _platformAccessToken: string | undefined;
  public timerToken = false;
  private _platformToken: Token | undefined;
  private myTimeout: any;
  public need_alert: boolean = true;
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private dbService: NgxIndexedDBService,
    private _utils: UtilsService
  ) {
    this.localPlatformToken();
  }

  //Token platform
  requestPlatformToken(): Observable<string> {
    const body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('scope', 'read write');
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + environment.ACCESS_TOKEN_PAGE,
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this._http.post<any>(`${environment.BACKEND_URL}auth/token/`, body?.toString(), { headers }).pipe(
      tap((res: any) => {
        this.platformToken = { ...res, generated: Date.now() };
        // this.platformToken.expires_in = (120);
        clearTimeout(this.myTimeout);
        this.myTimeout = undefined;
        this.renewToken((this.platformToken.expires_in * 1000) - (60 * 1000));
      })
    );
  }

  set platformAccessToken(token: any) {
    // console.log('set platformAccessToken', token);
  }

  get platformAccessToken() {
    return this.platformToken?.access_token;
  }

  set platformToken(token: any) {
    if (token) {
      this._platformToken = token;
      localStorage.setItem('ai-platform-token', this._utils.encrypt(token, environment.INDEXDB.SECRET_KEY));
    }
  }

  get platformToken(): any {
    if (this._platformToken == undefined) {
      this.localPlatformToken();
    }
    return this._platformToken;
  }

  public logOut() {
    clearTimeout(this.myTimeout);
    this.myTimeout = undefined;
  }

  renewToken(retry_time: number) {
    this.myTimeout = setTimeout(() => {
      this.requestPlatformToken().subscribe({
        next: (data: any) => { }
      })
    }, retry_time);
  }

  localPlatformToken() {
    const token = localStorage.getItem('ai-platform-token');
    if (token) {
      const decrypt = this._utils.decrypt(token, environment.INDEXDB.SECRET_KEY);
      // decrypt.expires_in = 120
      if ((decrypt.generated + ((decrypt.expires_in * 1000) - (60 * 1000))) > Date.now()) {
        this._platformToken = decrypt;
        this.renewToken((decrypt.generated + ((decrypt.expires_in * 1000) - (60 * 1000))) - Date.now());
      } else {
        this._platformToken = undefined;
      }
    }
  }

  //Generador de peticiones
  //Se postula para controlar todas las peticiones y organizar tokens y urls

  getKey(request: HttpRequest<unknown>) {
    let request_body = JSON.stringify(request.body);
    const paramsString = request.params?.toString() ?? '';
    return [MD5(request_body + request.url + paramsString)?.toString(), request_body];
  }

  public generateRequest(
    type: 'get' | 'post' | 'patch' | 'put' | 'delete',
    token: 'user' | 'page',
    url: string,
    data: any = {},
    query?: string,
    endpoint?: string,
    headers?: HttpHeaders,
    storage: any = true
  ) {
    if (url[url.length - 1] != '/') url += '/';
    let queryParams = new HttpParams();
    if (query) {
      query.split('&').forEach(function (value) {
        let aux = value.split('=');
        queryParams = queryParams.append(aux[0], aux[1]);
      });
    }
    if (!headers) headers = new HttpHeaders;


    if (storage) headers = headers.append('AgrodatAi-Storage', storage?.toString());

    headers = headers.append('AgrodatAi-Token', token);

    const request = new HttpRequest(type.toUpperCase(), `${endpoint ? endpoint : environment.BACKEND_URL}${url}`, data, { params: queryParams, headers: headers });
    let [key] = this.getKey(request);

    return new Observable((observer) => {
      if (!storage) {
        this.Makerequest(request, observer);
      } else {
        this.dbService.getByKey('request', key).subscribe((data: any) => {
          if (data) {
            if (data.expires_in > Date.now()) {
              // console.log('generateRequest', data.body, key, request.body, request, request.url);
              observer.next(JSON.parse(data.body));
            } else {
              this.dbService.delete('request', key).subscribe((data) => { });
              data = undefined;
            }
          }
          if (!data) {
            this.Makerequest(request, observer);
          }
        });
      }
    });


  }

  private Makerequest(request: HttpRequest<any>, observer: Subscriber<unknown>) {
    this._http.request(request).pipe(
      catchError((err) => {
        console.log('err', err);
        observer.error(err);
        return throwError(err);
      })
    ).subscribe(
      event => {
        if (event.type === HttpEventType.DownloadProgress) {
          // console.log("Download progress event", event);
        }
        if (event.type === HttpEventType.UploadProgress) {
          // console.log("Upload progress event", event);
        }
        if (event.type === HttpEventType.Response) {
          // TODO validar los eventos response de error
          // console.log("response received...", event.body, event.status);
          observer.next(event.body);
        }
      });
  }
}
