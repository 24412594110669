import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, } from "@angular/router";
import { UserService } from "../user.service";
import { CommonService } from "../common.service";

export const FarmsCanActivateFn: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, _user: UserService = inject(UserService), _common: CommonService = inject(CommonService), _router: Router = inject(Router)) => {
    if (_user.locations && _user.locations.length > 0) {
      const NonProductsLocation = _user.locations.find((location) => location.products === undefined || location.products.length <= 0);
      if (NonProductsLocation) {
        _router.navigate([`/home/farms/products/` + NonProductsLocation.id]);
        return false;
      }
      _common.showHeader = true;
      return true;
    }
    _common.showHeader = false;
    _router.navigate(['/home/farms']);
    return false;
  }

export const FarmsCanActivateChildFn: CanActivateChildFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, _router: Router = inject(Router), _common: CommonService = inject(CommonService), _user: UserService = inject(UserService)) => {
    if (state.url.search('products') >= 0 || state.url.search('maps') >= 0) {
      if (_user.locations && _user.locations.length > 0) return true
      else {
        _common.showHeader = false;
        _router.navigate(['/home/farms']);
        return false;
      }
    }
    else if (state.url.search('farms') && (_user.locations && _user.locations.length == 0)) {
      _common.showHeader = false;
      return true;
    }
    _common.showHeader = true;
    return true;
  }
