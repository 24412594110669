import { Component, OnInit } from '@angular/core';
import { CommonService } from './common/services/common.service';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UserService } from './common/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  entry: boolean = false;
  public frequency_l: boolean = true;

  private isInStandaloneMode: any;
  private deferredPrompt: any;
  public banner = false;
  public bannerIOS = false;
  public prod = environment.production


  constructor(
    private _common: CommonService,
    private readonly updates: SwUpdate,
    private _analytics: AngularFireAnalytics,
    private _user: UserService
  ) {
    window.addEventListener('online', () => _common.handleConnectionChange());
    window.addEventListener('offline', () => _common.handleConnectionChange());
    window.addEventListener('resize', () => this.checkViewport());
    this.checkViewport();
  }

  private deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      // screen.orientation.lock('portrait');
      return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      //!FIXME: No funciona
      // screen.orientation.lock('portrait');
      return "mobile";
    }
    return "desktop";
  };

  ngOnInit() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }

    this.isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

    if (isIos() && !this.isInStandaloneMode()) {
      this.bannerIOS = true;
    }

    this._analytics.logEvent('launch', { user_id: this._user?.user?.id, deviceType: this.deviceType(), description: this.getPWADisplayMode() });

    // window.addEventListener('beforeinstallprompt', (e) => {
    //   // Prevent the mini-infobar from appearing on mobile
    //   e.preventDefault();
    //   console.log('se realzia ajuste', this.banner);
    //   // Stash the event so it can be triggered later.
    //   this.deferredPrompt = e;
    //   // Update UI notify the user they can install the PWA
    //   this.banner = true;
    // });

    // window.addEventListener('appinstalled', (e) => {
    //   // Hide the app-provided install promotion
    //   this.banner = false;
    //   // Clear the deferredPrompt so it can be garbage collected
    //   this.deferredPrompt = null;
    //   // Optionally, send analytics event to indicate successful install
    //   console.log('PWA was installed');
    //   this._analytics.logEvent('install', { user_id: this._user?.user?.id, deviceType: this.deviceType(), description: 'PWA was installed' });
    // });
  }

  private getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return 'twa';
    } else if (this.isInStandaloneMode || isStandalone) {
      return 'standalone';
    }
    return 'browser';
  }

  public install() {
    if (this.deferredPrompt) {
      this.banner = false;
      // Show the install prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        console.warn(choiceResult);
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          this._analytics.logEvent('install', { user_id: this._user?.user?.id, deviceType: this.deviceType(), description: 'User accepted the install prompt' });
        } else {
          console.log('User dismissed the install prompt');
          this._analytics.logEvent('install', { user_id: this._user?.user?.id, description: 'User dismissed the install prompt' });
        }
      });
    }
  }


  private checkViewport() {
    if (window.screen.width <= 500) this._common.device = 'mobile';
    else if (window.screen.width > 500 && window.screen.width <= 1336) this._common.device = 'tablet';
    else if (window.screen.width > 1336) this._common.device = 'web';
  }
}
