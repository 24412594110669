import { AiAlert } from '@agrodatai/alerts';
import { AbstractComponentTranslate } from '@agrodatai/core';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/services/common.service';
import { StartAppService } from 'src/app/common/services/startApp.service';
import { UserService } from 'src/app/common/services/user.service';

@Component({
  selector: 'ai-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends AbstractComponentTranslate implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean = true;
  //Styles skeleton
  public templateStyle = {
    width: '4rem',
    height: '4rem',
    'border-radius': '5px'
  };
  bannerStyle = { ...this.templateStyle };
  public spamCards = [
    {
      title: 'credit',
      image: 'https://storage.googleapis.com/front-agrodatai-dev/agrodatai/home/don-tulio-credito.svg',
      classList: 'bg-purple-1 color-gray-3',
      // redirectTo: ['/home/credit'],
      external: "https://agrodatai.page.link/scDe",
    },
    {
      title: 'secure',
      image: 'https://storage.googleapis.com/front-agrodatai-dev/agrodatai/home/nuevo-home-don-tulio-seguros.svg',
      classList: 'bg-green-2 color-gray-3',
      redirectTo: ['/home/insurance']
      // external: "https://agrodatai.page.link/aMj4"
    },
  ]
  //end Styles Skeleton
  constructor(
    private _common: CommonService,
    private _start: StartAppService,
    private _user: UserService,
    private _alert: AiAlert,
    private _router: Router
  ) {
    super(_start, 'home');
    this.bannerStyle.width = '100%';
    this.bannerStyle.height = '18rem';
  }

  ngOnInit() {
    this.checkDNI();
  }
  ngAfterViewInit(): void {
    setTimeout(() => this.isLoading = false, 300)
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private checkDNI() {
    if (this._user.user.person.user_document == null) {
      this._alert.readyAlert({
        type: 'info',
        image: 'info',
        title: '¡Hola!, ¡hace rato no sabemos de ti!',
        text: "Estamos actualizando nuestra aplicación para ofrecerte mejores servicios. Ayúdanos, actualizando tus datos.",
        showConfirmButton: true,
        textConfirmButton: "Completar datos"
      }).then((response) => {
        if (response.isConfirmed) {
          this._router.navigate(['/home/profile']);
        }
      })
    }
  }

  redirect(card: any) {
    if (card.redirectTo) this._router.navigate(card.redirectTo);
    else window.open(card.external, '_blank');
  }
}
