import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from "@angular/router";
import { Observable, map, of, take } from "rxjs";
import { StartAppService } from "../startApp.service";

export const InitCanActivateFn: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, _router: Router = inject(Router), _start: StartAppService = inject(StartAppService)): Observable<boolean> => {
    return new Observable<boolean>((observable) => {
      _start.configureAppFn(state.url).pipe(
        take(1),
        map(status => {
          if (!status.status) {
            _router.navigate([status.route]);
            observable.next(false);
          }
          document.querySelector('#principal_loader')?.classList.add('ai-loader-hide');
          observable.next(true);
        })).subscribe({
          next: ()=>{}
        })
    })
  }
