export const environment = {
  ACCESS_TOKEN_PAGE: 'eHQ5R3lPRzNTNFJteWFvcXdWV2FvWXFkZ0tMaVZETnN2bGNoWkhBdjpYUGNVcENqUlc3TUxYQ3V2QTlmdzIwQUxQWkdNMk1vUE1RRGdIUVdKVzV0MjhCZGdDbjIwb29USTkzdnU5ZHNS',
  firebaseConfig: {
    apiKey: "AIzaSyA_joNAKY7OrCAuukmUtL2CV41dNuhQcz4",
    authDomain: "cebar-servinf-agrodat-ai-prod.firebaseapp.com",
    databaseURL: "https://cebar-servinf-agrodat-ai-prod.firebaseio.com",
    projectId: "cebar-servinf-agrodat-ai-prod",
    storageBucket: "unscanned-files-backend-prod",
    messagingSenderId: "199178709204",
    appId: "1:199178709204:web:a013dc463e0b3095913341",
    measurementId: "G-CCS373F0F3"

  },
  production: true,
  INDEXDB: {
    SECRET_KEY: 'ai-fYqp4J7hHXrYJTJvbxfbNx5e8t2z6MFxHMwgaz6h',
    DATABASE_NAME: 'ai-database',
    TABLES: {
      USER: "ai-users"
    }
  },
  TOKEN: {
    bearer: "Gv0D3p80MMRaqIDr03iU77wlV6ULYu"
  },
  BACKEND_URL: 'https://backend-platform-ceqsqfqcwa-uc.a.run.app/',
  BACKEND_PRICES_URL: 'https://microservice-prices-ceqsqfqcwa-uc.a.run.app/',
  BACKEND_BETTER_URL: 'https://microservice-better-ceqsqfqcwa-uc.a.run.app/',
  BACKEND_WEATHER_URL: 'https://microservice-weather-ceqsqfqcwa-uc.a.run.app/',
  expires_cache: 120,
  location_time: 150,
  default_storage: false,
  debug_mode_analytics: false,
  indexedDBCOnfig: {
    name: 'platform',
    version: 1,
    objectStoresMeta: [{
      store: 'request',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'body', keypath: 'body', options: { unique: false } },
        { name: 'create_date', keypath: 'create_date', options: { unique: false } },
        { name: 'expires_in', keypath: 'expires_in', options: { unique: false } },
        { name: 'request', keypath: 'request', options: { unique: false } },
      ]
    }]
  },
  MAPCONFIG: {
    ID: '5bf97415c907fd78',
    APIKEY: 'AIzaSyA2nt7WTUh_f7rArhuYjgwYc0ohnAv2e7g',
    VERSION: 'beta',
    LANG: 'es',
    LIBRARIES: [
      'drawing',
      'maps',
      'streetView',
      'marker'
    ]
  },
  PREFIX_FIREBASE: 'prod_',
  PREFIX_APP: "",
  ENVIRONMENT_DEPLOY: "",
  KEYS: {
    QPARAMS: "ai-8cac7a2a124098b3b8d84fa3a404922c",
    QPARAMSNAMES: {
      CREDIT: {
        bankId: "b"
      }
    }
  }
}
