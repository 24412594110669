import { CommonModule } from '@angular/common';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AiCreditModule, AiCreditService } from "@agrodatai/credit";
import { AiEntryModule } from '@agrodatai/entry';
import { AiFarmsModule } from '@agrodatai/farms'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService } from './common/services/common.service';
import { AiConnectModule } from '@agrodatai/connect';
import { RootModule } from './modules/root/root.module';
import { AiAlertsModule } from "@agrodatai/alerts";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firestore config
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
// Translante
import { AiSkeletonDirective } from '@agrodatai/core';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { UserService } from './common/services/user.service';

import { TokenInterceptor } from './common/services/interceptor/token.service';
import { CredentialsService } from './common/services/credentials.service';
import { LoaderComponent } from './components/loader/loader.component';

import { AngularFireAnalytics, AngularFireAnalyticsModule, CONFIG, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';

// Storage / Offline
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { StorageInterceptor } from './common/services/interceptor/storage.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StorageModule } from '@angular/fire/storage';
import { FirebaseAppModule } from '@angular/fire/app';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AiCreditModule,
    AiEntryModule,
    AiFarmsModule,
    HttpClientModule,
    AiConnectModule,
    //TODO validar la configuracion de offline para firebase
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    RootModule,
    LoaderComponent,
    AiSkeletonDirective,
    AiAlertsModule,
    NgxIndexedDBModule.forRoot(environment.indexedDBCOnfig),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // TODO: cargar todo lo que sea necesario en los asserts, apra que los service workers descargen el contenido y pueda funcionar offline
      registrationStrategy: 'registerWhenStable:6000'
    }),
    StorageModule,
    FirebaseAppModule
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    { provide: 'commonService', useExisting: CommonService },
    { provide: 'UserService', useExisting: UserService },
    { provide: 'CredentialsService', useExisting: CredentialsService },
    { provide: HTTP_INTERCEPTORS, useClass: StorageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: CONFIG, useValue: {
        send_page_view: true,
        allow_ad_personalization_signals: true,
        anonymize_ip: false,
        debug_mode: environment.debug_mode_analytics,
        app_name: 'Platform',
        app_version: '1.1.3'
      }
    },
    ScreenTrackingService,
    UserTrackingService,
    { provide: 'AngularFireAnalytics', useExisting: AngularFireAnalytics },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    console.log('isDevMode()', isDevMode())
  }
}
