import { Injectable } from '@angular/core';
import User from '../interfaces/User';
import { UtilsService } from "@agrodatai/core";
import { environment } from '../../../environments/environment';
import Token from '../interfaces/Token';
import { HttpClient } from '@angular/common/http';
import { CredentialsService } from './credentials.service';
import { AES, enc } from 'crypto-js';
import { Observable, Subject, Subscription, combineLatest, concatMap, delay, forkJoin, interval, map, of, switchMap, tap } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
// import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AiAlert } from '@agrodatai/alerts';
import { Location } from '../interfaces/Common';

import { NgxIndexedDBService } from 'ngx-indexed-db';

// TODO: validar el refresh token del usuario y la subscripcion del mismo ya que si se cierra sesion se debe limpiar la funcion (no volverse a ejecutar)
// TODO: validar la redireccion en el momento del login

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User | undefined;
  public user_firebase: any;
  public _token: Token | undefined;
  public user_fire_ref: any;
  private id_location: any;
  public myTimeout: any;
  // private _farms: Location[] = [];


  constructor(
    private _utils: UtilsService,
    private _http: HttpClient,
    private _credentials: CredentialsService,
    private _dbFire: AngularFirestore,
    private _common: CommonService,
    private _router: Router,
    private _analytics: AngularFireAnalytics,
    private _alert: AiAlert,
    private dbService: NgxIndexedDBService
  ) {

  }

  set user(user: User | undefined) {
    if (user) {
      this._user = user;
      localStorage.setItem('ai-user', this._utils.encrypt(user, environment.INDEXDB.SECRET_KEY));
      this._analytics.setUserId(user!.user.id.toString());
      this._analytics.setUserProperties({ groups: user!.user.groups, company: user!.user.company });
    } else {
      this.clear();
    };
  }

  get user(): any {
    if (this._user == undefined) {
      const user = localStorage.getItem('ai-user');
      if (user) {
        const decrypt = this._utils.decrypt(user, environment.INDEXDB.SECRET_KEY);
        this._user = decrypt
      } else this._user = undefined;
    }
    return this._user?.user;
  }

  set locations(farms: Location[] | undefined) {
    this._user!.locations = farms;
    this.user = this._user;
  }

  get locations(): Location[] | undefined {
    return this._user?.locations
  }

  set token(token: Token | undefined) {
    if (token) {
      localStorage.setItem('ai-token', this._utils.encrypt(token, environment.INDEXDB.SECRET_KEY));
      this._token = token;
    } else {
      this.clear();
    };
  }

  get token(): Token | undefined {
    if (this._token == undefined) {
      const token = (localStorage.getItem('ai-token'));
      if (token) {
        const decrypt = this._utils.decrypt(token, environment.INDEXDB.SECRET_KEY);
        // decrypt.expires_in = (120);
        this._token = decrypt;
      } else {
        this.token = undefined
        this.user = undefined;
      };
    }
    return this._token;
  }

  clear() {
    localStorage.removeItem('ai-user');
    localStorage.removeItem('ai-token');
    this._user = undefined;
    this._token = undefined;
    this.user_fire_ref = undefined;
    this.user_firebase = undefined;
  }

  tokenis_current() {
    if (this.token) {
      if ((this.token.generated + ((this.token.expires_in * 1000) - (60 * 1000))) > Date.now()) {
        this.renewToken((this.token.generated + ((this.token.expires_in * 1000) - (60 * 1000))) - Date.now(), 'local token');
        return true
      } else {
        return false
      }
    } else {
      return null
    }
  }

  getUserFirebase(user: User | undefined): Observable<any> {
    const user_id = (user?.user?.id) ? user?.user?.id : this.token?.user_id;
    this.user_fire_ref = this._dbFire.doc(`${environment.PREFIX_FIREBASE}users/${user_id}`);
    return this.user_fire_ref.snapshotChanges().pipe(
      map((a: any) => {
        const data = a.payload.data();
        if (data) {
          data['id'] = a.payload.id;
          return { data: data, type: a.type };
        }
        return a;
      }),
      tap((res: any) => {
        this.user_firebase = res.data;
      }));
  }

  // public detailUserFarms(id_user: string) {
  //   return this._credentials.generateRequest(
  //     'get', 'user', `apps/locations_user/products/`,
  //     {}, `user=${id_user}`,undefined,undefined,false
  //   ).pipe(
  //     tap((res: any) => {
  //       if (res.results && res.results.length > 0) {

  //       }
  //     }),
  //     map((res: any) => ({ ...res }))
  //   );
  // }

  public login(path: string, credentials: { username: string, password: string } | { firebase_token: string }) {
    return this._credentials.generateRequest(
      'post', 'page', path,
      credentials,
      undefined, undefined, undefined, false
    ).pipe(
      tap((res: any) => {
        this.token = { ...res, generated: Date.now() };
        // res.expires_in = (120);
        this.renewToken((res.expires_in * 1000) - (60 * 1000), 'login');
      }),
      switchMap(response => this.getDetails(response))
    );
  }

  public renewToken(retry_time: number, location: string) {
    if (this.myTimeout == undefined) {
      this.myTimeout = setTimeout(() => {
        this.getToken().subscribe({
          next: (data: any) => { },
          error: async (err: any) => {
            this._alert.readyAlert({
              type: 'warning',
              image: 'rita',
              title: `${err.messages.non_field_errors}`,
              timer: 3000
            });
          }
        })
      }, retry_time);
    }
  }

  public logOut = async () => {
    this.user = undefined;
    clearTimeout(this.myTimeout);
    this.myTimeout = undefined;
    // this._credentials.logOut();
    this.dbService.clear('request').subscribe((successDeleted) => {
    });
    this._router.navigate(['/login']);
  }

  public getDetails(token: any) {
    return this._credentials.generateRequest(
      'get', 'user', 'apps/profiles/detailed_profile/'
    ).pipe(
      tap((res: any) => {
        this.user = res;
      })
    )
  }

  getToken(): Observable<string> {
    return this._credentials.generateRequest(
      'post',
      'page',
      'auth/refresh_token/',
      { refresh_token: this?.token?.refresh_token },
      undefined,
      undefined,
      undefined,
      false
    ).pipe(
      tap((res: any) => {
        this.token = { ...res, generated: Date.now(), refresh_token: this?.token?.refresh_token };
        clearTimeout(this.myTimeout);
        this.myTimeout = undefined;
        this.renewToken((res.expires_in * 1000) - (60 * 1000), 'generateRequest');
      })
    );
  }

  public getCurrentPosition(alerts: boolean = false): Observable<any> {
    return new Observable((observer) => {
      if (this.user_firebase?.last_location) {
        observer.next(this.user_firebase.last_location);
      } else {
        this.getLocation();
        setTimeout(() => {
          observer.next(this.user_firebase?.last_location);
        }, 500);
      }
    });
  }

  getLocation(alerts: boolean = false) {
    if (navigator.geolocation) {
      let that = this;
      let opts = { enableHighAccuracy: true, maximumAge: 60000, timeout: (environment.location_time * 1000) };
      const handleSuccess = (position: any) => {
        let coords = {
          accuracy: position.coords.accuracy,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        let coords2 = {
          accuracy: this.user_firebase?.last_location?.coords?.accuracy,
          latitude: this.user_firebase?.last_location?.coords?.latitude,
          longitude: this.user_firebase?.last_location?.coords?.longitude
        };
        if (position) {
          if (that.user_fire_ref) {
            if (!(JSON.stringify(coords) === JSON.stringify(coords2))) {
              let data = {
                last_location: {
                  timestamp: position.timestamp,
                  coords: {
                    accuracy: position.coords.accuracy,
                    altitude: position.coords.altitude,
                    altitudeAccuracy: position.coords.altitudeAccuracy,
                    heading: position.coords.heading,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    speed: position.coords.speed
                  }
                }
              };
              if (this.user_firebase) this.user_firebase['last_location'] = data;
              try {
                that.user_fire_ref.update(data);
              } catch (error) {
                console.error(error, that.user_fire_ref, that.user, that.user_firebase)
              }
            }
          }
        }
      };
      const handleError = (error: any) => {
        if (alerts) {
          this._alert.readyAlert({
            type: 'error',
            image: 'explode_head',
            // TODO: se puede mejorar con traducciones
            title: 'No se puede acceder a tu ubicación. Asegúrate de tener la opción de ubicación activada en tu navegador.',
            text: `${error.message}`,
            timer: 5000,
            showConfirmButton: false
          });
        }
        console.error(`ERROR(${error.code}): ${error.message}`);
      };
      navigator.geolocation.getCurrentPosition(handleSuccess)
      this.id_location = navigator.geolocation.watchPosition(handleSuccess, handleError, opts);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
}


