<div class="ai-app-content">
  <ai-home-header
    class="w-100 header-content {{_user.locations && _user.locations.length > 0 ? '': 'hide__element'}}"></ai-home-header>
  <ai-home-menu
    class="menu-content w-100 {{_user.locations && _user.locations.length > 0 ? '': 'hide__element'}}"></ai-home-menu>
  <main class="main-content w-100">
    <router-outlet></router-outlet>
  </main>
  <ai-home-sub-menu
    class="footer-content w-100 {{_user.locations && _user.locations.length > 0 ? '': 'hide__element'}}"></ai-home-sub-menu>
  <ai-information></ai-information>
</div>