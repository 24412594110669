<div *ngIf="_common.AgroLoader" class="ai-loader-back">
  <svg class="ai-loader-logo ai-online" viewBox="0 0 34 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- Hojas verdes -->
    <path class="ai-leaf ai-leaf-green-1"
      d="M0.533813 0.762783C0.533812 0.17331 1.17481 -0.19261 1.68241 0.107094L15.065 8.00869C15.7278 8.40002 16.1345 9.11241 16.1345 9.88209V23.4365C16.1345 24.0224 15.5005 24.3888 14.9929 24.0963L1.62318 16.392C0.949183 16.0036 0.533842 15.2849 0.533841 14.507L0.533813 0.762783Z" />

    <path class="ai-leaf ai-leaf-green-2"
      d="M0.533813 18.4244C0.533812 17.835 1.17481 17.4691 1.68241 17.7688L15.065 25.6704C15.7278 26.0617 16.1345 26.7741 16.1345 27.5438V41.0982C16.1345 41.6841 15.5005 42.0505 14.9929 41.7579L1.62318 34.0537C0.949183 33.6653 0.533842 32.9466 0.533841 32.1687L0.533813 18.4244Z" />

    <path class="ai-leaf ai-leaf-green-3"
      d="M0.533813 36.2269C0.533812 35.6374 1.17481 35.2715 1.68241 35.5712L15.065 43.4728C15.7278 43.8641 16.1345 44.5765 16.1345 45.3462V58.9006C16.1345 59.4865 15.5005 59.8529 14.9929 59.5604L1.62318 51.8561C0.949183 51.4677 0.533842 50.749 0.533841 49.9711L0.533813 36.2269Z" />

    <!-- Hojas purpura -->
    <path class="ai-leaf ai-leaf-purple-1"
      d="M33.4662 0.76261C33.4662 0.173175 32.8253 -0.19275 32.3177 0.106878L18.9312 8.00873C18.2684 8.40002 17.8616 9.11248 17.8616 9.88225V23.4367C17.8616 24.0225 18.4955 24.3889 19.0031 24.0965L32.3767 16.392C33.0508 16.0036 33.4662 15.2849 33.4662 14.5069L33.4662 0.76261Z" />

    <path class="ai-leaf ai-leaf-purple-2"
      d="M33.4662 18.4243C33.4662 17.8348 32.8253 17.4689 32.3177 17.7685L18.9312 25.6704C18.2684 26.0617 17.8616 26.7741 17.8616 27.5439V41.0984C17.8616 41.6842 18.4955 42.0506 19.0031 41.7582L32.3767 34.0537C33.0508 33.6653 33.4662 32.9465 33.4662 32.1685L33.4662 18.4243Z" />

    <path class="ai-leaf ai-leaf-purple-3"
      d="M32.3177 35.5709C32.8253 35.2713 33.4663 35.6372 33.4662 36.2267L33.4662 49.9709C33.4662 50.7489 33.0508 51.4677 32.3767 51.856L19.741 59.1354V63.0268C19.741 63.5458 19.3203 63.9665 18.8013 63.9665C18.2823 63.9665 17.8616 63.5458 17.8616 63.0268V45.3463C17.8616 44.5765 18.2684 43.8641 18.9312 43.4728L32.3177 35.5709Z" />
  </svg>
</div>