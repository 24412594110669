<div *ngIf="validateHeader()" class="ai-sub-menu family-quicksand">
  <ng-container *ngFor="let option of options; index as i">
    <div [routerLinkActive]="'select'" [routerLinkActiveOptions]="{exact: option.id ==='home'?true:false}"
      (click)="redirect(option)" class="ai-sub-menu-option">
      <img [style.--width]="option.size[0]" [style.--height]="option.size[1]" class="ai-sub-menu-option-image"
        [src]="option.path+option.image" [alt]="'Image to Option'+option.text+''">
      <div class="ai-sub-menu-option-text">
        {{translation && translation[option.id]?translation[option.id]:option.id+'?'}}
      </div>
    </div>
  </ng-container>
</div>